import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import NewsList from "./NewsList"
import ContentFilters from "../../components/ContentFilters"

const NewsIndex = props => {
  const ContentFiltersComponent = () => (
    <ContentFilters
      rootURL={`/news/`}
      offices={props.data.allOffices}
      indexType={`news`}
    />
  )

  return (
    <Layout>
        <SEO title={`News`} description={`An inside look at what's happening across W+K.`} />
        <div>
          <NewsList
            ContentFilters={ContentFiltersComponent}
            allNews={props.data.allNews}
          />
        </div>
    </Layout>
  )
}

export default NewsIndex

export const query = graphql`
  query NewsIndex {
    allBrands: allContentfulClients(
      sort: { fields: [brandName], order: ASC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          node_locale
          brandName
          slug
          news {
            ...newsFragmentBase
          }
        }
      }
    }

    allOffices: allContentfulOffices(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "en-US" }, venture: { eq: false } }
    ) {
      edges {
        node {
          id
          node_locale
          officeName
          slug
          news {
            ...newsFragmentBase
          }
        }
      }
    }

    allNews: allContentfulNews(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "en-US" }, displayOnWorking: { eq: false } }
    ) {
      edges {
        node {
          ...newsFragmentBase
        }
      }
    }
  }
`
